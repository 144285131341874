<template>
    <v-card class="elevation-12" id="login-card">
      <v-toolbar dark color="primary" flat>
        <v-toolbar-title>{{ $t('auth.userDetails') }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <validation-provider ref="emailprovider" :name="$t('auth.usernameLabel')" rules="required">
          <v-text-field
            prepend-icon="person"
            :label="$t('auth.usernameLabel')"
            type="text"
            v-model="form.username"
            slot-scope="{ errors }"
            :error-messages="errors"
            :loading="loading"
            :disabled="loading"
            :placeholder="isAutoFilled ? ' ' : ''"
          ></v-text-field>
        </validation-provider>

        <validation-provider ref="pwprovider" :name="$t('auth.passwordLabel')" rules="required">
          <v-text-field
            prepend-icon="lock"
            name="password"
            :label="$t('auth.passwordLabel')"
            type="password"
            v-model="form.password"
            slot-scope="{ errors }"
            :error-messages="errors"
            :loading="loading"
            :disabled="loading"
            :placeholder="isAutoFilled ? ' ' : ''"
          ></v-text-field>
        </validation-provider>
      </v-card-text>
      <v-card-actions>
        <forgot-password
          v-if="authMethod === 'custom_limetype'"
        />
        <v-btn
          text
          color="primary"
          ripple
          small
          @click="openRegisterForm"
        >
          Registrera dig
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          type="submit"
          :loading="loading"
          :disabled="loading"
          @click.prevent="submit"
        >
          {{ $t('auth.login') }}
        </v-btn>
      </v-card-actions>
    </v-card>
</template>

<script>
import ForgotPassword from './../ForgotPassword.vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';

export default {
  props: {
    authMethod: {
      required: true,
      type: String
    }
  },

  components: {
    ValidationObserver,
    ValidationProvider,
    ForgotPassword
  },

  data() {
    return {
      form: {
        username: '',
        password: '',
      },
      isAutoFilled: false,
      loading: false
    }
  },

  methods: {
    openRegisterForm() {
      window.open('https://trelleborg.lime-forms.se/forms/1fuquGxfLVWCqMY5mlBC', '_blank').focus();
    },

    async submit() {
      // dont use validationobserver for validation, since we have a nested observer in ForgotPassword.vue
      let valid = await Promise.all(this.validationproviders().map(async p => {
        let v = await p.validate();
        return v.valid;
      }));
      valid = _.every(valid);
      if (! valid) return;

      this.loading = true;

      let url = this.authMethod === 'lime_user' ?
        this.route('login-lime-user.request').toString() :
        this.route('login-custom-limetype.request').toString();

      axios.post(url, this.form)
      .then(res => {
        if (_.get(res, 'data.success') === true) {

          window.location.reload();
        }
      })
      .catch(err => {
        const status = _.get(err, 'response.status');
        const TOO_MANY_REQUESTS = 429;
        let message;

        if (status === TOO_MANY_REQUESTS) {
          const username = _.head(_.keys(_.get(err, 'response.data.errors')));
          message = _.head(_.get(err, `response.data.errors.${username}`));
        } else {
          message = _.get(err, 'response.data.message', this.$t('common.somethingWentWrong'));
        }

        this.form.password = '';

        this.$store.commit('snack/SET', {
          message,
          color: 'error',
          timeout: 0
        });
      })
      .finally(() => {
        this.loading = false;
      });
    },

    validationproviders(){
      return [this.$refs.emailprovider, this.$refs.pwprovider];
    }
  },

  beforeCreate(){
    this.$nextTick(() => {
      let ival = setInterval(() => {
        let els = this.$el.querySelectorAll('input');
        if (els.length) {
          clearInterval(ival);
          els.forEach(el => {
            el.addEventListener('transitionstart', (e) => {
              if (e.propertyName === 'background-color') {
                this.isAutoFilled = true;
              }
            });
            el.addEventListener('animationstart', (e) => {
              if (e.animationName === 'onAutoFillStart') {
                this.isAutoFilled = true;
              }
            });
          })
        }
      }, 50);
    });
  }
}
</script>
<style>
@keyframes onAutoFillStart {
  from {/**/}
  to {/**/}
}
#login-card input:-webkit-autofill, #login-card input:-internal-autofill-selected {
  animation-name: onAutoFillStart;
  background-color: inherit;
  transition: background-color 50000s ease-in-out 0s;
}
</style>
