export default {
  common: {
    loading: 'Loading..',
    somethingWentWrong: 'Something went wrong!',
    close: 'Close',
    submit: 'Submit',
    cancel: 'Cancel',
    ok: 'OK',
    refresh: 'Refresh',
    yes: 'Yes',
    no: 'No',
    enter: 'Enter',
    create: 'Create'
  },
  limetype: {
    saveChanges: 'Save changes',
    noChanges: 'No changes made',
    updateSuccess: 'Success! The object was updated',
    requestError: code => `Request failed with status code: ${code}`,
    error: 'Something went wrong, please try again and contact an administrator if the problem persists.',
    filter: 'Filter',
    noData: 'No data found'
  },
  auth: {
    login: 'Login',
    loginUsingBankId: 'Login using BankID',
    userDetails: 'User Details',
    forgotPassword: 'Forgot your password?',
    forgotPasswordInfo: "Enter your email and we'll send you a link to a page where you can reset your password.",
    resetLinkSent: 'A reset email has been sent!',
    usernameLabel: 'Username',
    passwordLabel: 'Password',
    loginSuccess: 'You are now logged in!',
    personalNumberInputLabel: 'YYYYMMDDNNNN',
    personalNumberLabel: 'Personal number',
    emailLabel: 'Email',
    emailInputLabel: 'Enter your email',
    resetPassword: 'Reset Password',
    passwordLabel: 'Password',
    newPasswordLabel: 'New password',
    newPasswordInputLabel: 'Choose a new password',
    confirmPasswordLabel: 'Confirm new password',
    resetPasswordSuccess: 'Your password has been reset. You will now be redirected to the login page where you can login using your new password.',
    "urn:grn:authn:no:bankid": 'BankID - Mobile or Web (not working atm)',
    "urn:grn:authn:no:vipps": 'Vipps',
    "urn:grn:authn:no:bankid:central": "NO BankID - Code card",
    "urn:grn:authn:no:bankid:mobile": "NO BankID - Mobile",
    "urn:grn:authn:dk:nemid:poces": "NemID - Personal code card",
    "urn:grn:authn:dk:nemid:moces": "NemID - Employee code card",
    "urn:grn:authn:dk:nemid:moces:codefile": "NemID - Employee code file",
    "urn:grn:authn:se:bankid:another-device": "SE BankID - Other device",
    "urn:grn:authn:no:bankid:mobile:personalNumberInputLabel": "DDMMYYXXXXX",
    "urn:grn:authn:no:bankid:central:personalNumberInputLabel": "DDMMYYXXXXX",
    "urn:grn:authn:dk:nemid:poces:personalNumberInputLabel": "DDMMYYXXXX",
    "urn:grn:authn:dk:nemid:moces:personalNumberInputLabel": "DDMMYYXXXX",
    "urn:grn:authn:dk:nemid:moces:codefile:personalNumberInputLabel": "DDMMYYXXXX",
    "urn:grn:authn:no:vipps:personalNumberInputLabel": "DDMMYYXXXXX",
    "urn:grn:authn:se:bankid:another-device:personalNumberInputLabel": "YYYYMMDDXXXX",
    authMethodInputLabel: 'Choose a method',
    authMethodLabel: 'Authentication method',
    phoneNumberLabel: 'Mobile',
    NOPhoneNumberInputLabel: 'XXXXXXXX'
  },
  nav: {
    loggedInAs: 'Logged in as',
    logout: 'Logout',
    start: 'Start',
    index: 'List',
    create: 'Create'
  },
  startView: 'Start View',
  startViewNotConfigured: 'No start view configured. See /readme.txt in the root folder for instructions.',
  pageNotFoundTitle: 'Whoops, 404',
  pageNotFoundText: 'The page could not be found.'
}