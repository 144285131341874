export default {
  common: {
    loading: 'Laddar..',
    somethingWentWrong: 'Något gick fel!',
    close: 'Stäng',
    submit: 'Skicka',
    cancel: 'Avbryt',
    ok: 'OK',
    refresh: 'Uppdatera',
    yes: 'Ja',
    no: 'Nej',
    enter: 'Ange',
    create: 'Skapa'
  },
  limetype: {
    saveChanges: 'Spara ändringar',
    noChanges: 'Inga ändringar gjorda',
    updateSuccess: 'Lyckades! Objektet har uppdaterats',
    requestError: code => `Anropet misslyckades med statuskod ${code}`,
    error: 'Något gick fel, var vänlig försök igen och kontakta administratör om problemet kvarstår',
    filter: 'Filtrera',
    noData: 'Ingen data hittades'
  },
  auth: {
    login: 'Logga in',
    loginUsingBankId: 'Logga in med BankID',
    userDetails: 'Användaruppgifter',
    forgotPassword: 'Glömt lösenordet?',
    forgotPasswordInfo: 'Ange din e-postadress så skickar vi en länk till en sida där du kan återställa ditt lösenord.',
    resetLinkSent: 'Ett återställningsmail har skickats!',
    usernameLabel: 'Användarnamn',
    passwordLabel: 'Lösenord',
    loginSuccess: 'Du är nu inloggad!',
    personalNumberInputLabel: 'ÅÅÅÅMMDDNNNN',
    personalNumberLabel: 'Personnummer',
    emailLabel: 'E-postadress',
    emailInputLabel: 'Ange din e-postadress',
    resetPassword: 'Återställ lösenord',
    passwordLabel: 'Lösenord',
    newPasswordLabel: 'Nytt lösenord',
    newPasswordInputLabel: 'Välj ett nytt lösenord',
    confirmPasswordLabel: 'Bekräfta lösenord',
    resetPasswordSuccess: 'Ditt lösenord har återställts! Du skickas snart till login-sidan där du kan logga in med ditt nya lösenord',
    "urn:grn:authn:no:bankid": 'Norskt BankID',
    "urn:grn:authn:no:vipps": 'Vipps',
    "urn:grn:authn:no:bankid:central": "Norskt BankID - Kodbricka",
    "urn:grn:authn:no:bankid:mobile": "Norskt BankID - Mobil",
    "urn:grn:authn:dk:nemid:poces": "NemID - Personligt nyckelkort",
    "urn:grn:authn:dk:nemid:moces": "NemID - Anställd med nyckelkort",
    "urn:grn:authn:dk:nemid:moces:codefile": "NemID - Anställd med kodfil",
    "urn:grn:authn:se:bankid:another-device": "Svenskt BankID - Annan enhet",
    "urn:grn:authn:no:bankid:mobile:personalNumberInputLabel": "DDMMÅÅXXXXX",
    "urn:grn:authn:no:bankid:central:personalNumberInputLabel": "DDMMÅÅXXXXX",
    "urn:grn:authn:dk:nemid:poces:personalNumberInputLabel": "DDMMÅÅXXXX",
    "urn:grn:authn:dk:nemid:moces:personalNumberInputLabel": "DDMMÅÅXXXX",
    "urn:grn:authn:dk:nemid:moces:codefile:personalNumberInputLabel": "DDMMÅÅXXXX",
    "urn:grn:authn:no:vipps:personalNumberInputLabel": "DDMMYYXXXXX",
    "urn:grn:authn:se:bankid:another-device:personalNumberInputLabel": "ÅÅÅÅMMDDXXXX",
    authMethodInputLabel: 'Välj en metod',
    authMethodLabel: 'Inloggningsmetod',
    phoneNumberLabel: 'Mobil',
    NOPhoneNumberInputLabel: 'XXXXXXXX'
  },
  nav: {
    loggedInAs: 'Inloggad som',
    logout: 'Logga ut',
    start: 'Start',
    index: 'Lista',
    create: 'Skapa'
  },
  startView: 'Startvy',
  startViewNotConfigured: 'Ingen startvy konfigurerad, se /readme.txt i rotkatalogen för instruktioner.',
  pageNotFoundTitle: 'Whoops, 404',
  pageNotFoundText: 'Sidan kunde inte hittas'
}